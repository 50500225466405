import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import SignUpData from '../Data/SignUp.json'
import { Layout } from '../components/AppLayout'
import {
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import ReCAPTCHA from 'react-google-recaptcha'
import gql from 'graphql-tag'
import { RDHelmet } from '../components/RDHelmet'
const ContactUsWrapper = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wave-background {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0;
    top: auto;
    right: 0px;
    left: 'auto';
    padding: 0;
    overflow: 'hidden';
  }
  .error-msg {
    font-size: 12px;
    text-align: start;
    padding: 1px;
    height: 16px;
    color: red;
  }
  form {
    overflow: hidden;
    max-width: 800px;
    min-width: 350px;
    min-height: 200px;
    margin-top: 60px;
    padding-top: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    .form-input {
      max-width: 600px;
      min-width: 350px;
      width: 90vw;
      margin-bottom: 0px;
      border-radius: 20px;
      border-color: #34a1d5;
      padding: 10px 10px 10px 10px;
      color: #003c86;
      max-height: 50px;
      border-style: none;
      background: #e1f4ff;
      font-family: 'GothamHTF', 'Helvetica Neue', sans-serif;
      font-size: 16px;
    }
    .form-input:focus {
      outline: none !important;
      color: #003c86;
    }
  }
  .submit-button {
    margin-top: 6px;
  }
  .privacypolicy {
    color: #34a1d5;
  }
`
export const SIGNUP_LEAD_GQL = gql`
  mutation upsert_lead($objects: [lead_insert_input!]!) {
    insert_lead(
      objects: $objects
      on_conflict: { constraint: lead_pkey, update_columns: [first_name, last_name, start_trial_at, company_name] }
    ) {
      affected_rows
    }
  }
`
const responseGoogle = (response: any) => {
  console.log(response)
}
const Demo = () => {
  const variants = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: 20 },
  }
  const { scrollYProgress } = useViewportScroll()
  const height = useTransform(scrollYProgress, [0, 0.5], [0, 300])
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0])
  const recaptchaRef = useRef(null)
  const [reset, setReset] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleError = () => {
    console.log('reCAPTACH handleError')
  }
  const handleExpired = () => {
    console.log('reCAPTCHA handleExpired')
  }
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity="success"
          style={{ borderRadius: 25 }}
          className="alert"
        >
          Your message has been sent, someone from the Raindrop team will get back to you soon.
        </MuiAlert>
      </Snackbar>
      <Dialog
        fullScreen={fullScreen}
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no
            apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal} color="primary">
            Disagree
          </Button>
          <Button onClick={handleCloseModal} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {reset && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          onErrored={handleError}
          onExpired={handleExpired}
          sitekey={process.env.GATSBY_RECAPTCHA_API_KEY_V2_INVISIBLE}
        />
      )}
      <Layout>
        <RDHelmet subtitle={'Demo'} meetingScript />
        <ContactUsWrapper>
          <div style={{ width: '100%', marginTop: 100, zIndex: 1000 }}>
            <div
              className="meetings-iframe-container"
              data-src="https://meetings.hubspot.com/vijay24/raindrop-demo-30-min?embed=true"
            ></div>
          </div>
          <img className="wave-background" src={SignUpData.ImagePath} />
        </ContactUsWrapper>
      </Layout>
    </>
  )
}
export default Demo
export const DotLoader = () => {
  const loadingContainer = {
    width: '10rem',
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-around',
  }
  const loadingCircle = {
    display: 'block',
    width: '0.5rem',
    height: '0.5rem',
    backgroundColor: '#34a1d5',
    borderRadius: '0.25rem',
  }
  const loadingContainerVariants = {
    start: {
      transition: {
        staggerChildren: 0.3,
      },
    },
    end: {
      transition: {
        staggerChildren: 0.4,
      },
    },
  }
  const loadingCircleVariants = {
    start: {
      y: '0%',
    },
    end: {
      y: '100%',
    },
  }
  const loadingCircleTransition = {
    duration: 0.8,
    yoyo: Infinity,
    ease: 'easeInOut',
  }
  return (
    <motion.div style={loadingContainer} variants={loadingContainerVariants} initial="start" animate="end">
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
      <motion.span style={loadingCircle} variants={loadingCircleVariants} transition={loadingCircleTransition} />
    </motion.div>
  )
}
